import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import Section from '../components/Section';
import Pagination from '../components/Pagination';

import styles from './visiting.module.css';

const VisitingPage = ({ data }) => {
  return (
    <Layout>
      <Section heading="Visiting mount mary over release weekends">
        <div className={styles.dates}>
          <h3>2019 ANNUAL RELEASE DAYS</h3>
          <p>Saturday 19th and Sunday 20th October</p>
          <p>Saturday 26th and Sunday 27th October</p>
        </div>
        <div className={styles.flex}>
          <Img fluid={data.image.childImageSharp.fluid} className={styles.image} />
          <div className={styles.flexText}>
            <p>
              Release weekends are the time of the year we love the most! It’s
              great catching up with you all, both old and new faces alike. If you have
              only recently joined the Mailing List, we would love you to join us at
              Mount Mary for one of these days in October. It’s a great way to meet the
              team and taste the new release wines while enjoying some delicious local
              cheeses. It’s also a unique opportunity to chat with the team about the new
              release 2017 vintage and wines and see where all the action happens at
              Mount Mary. We look forward to seeing you all in October.
            </p>
            <p className={styles.highlight}>
              Attendance is strictly via RSVP on your order form and it’s limited to two
              attendees per order. Please refer to the delivery details section on your
              order form for further information.
            </p>
          </div>
        </div>
      </Section>
      <Pagination
        forwardLink="/marli-russell"
        backLink="/events"
      />
    </Layout>
  );
};

VisitingPage.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.object,
  }),
};

export const query = graphql`
  query {
    image: file(relativePath: { eq: "visiting.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default VisitingPage;
